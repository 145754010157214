<template>
    <div 
        class="container"
    >
  
            <slot />
     
        
    </div>
</template>

<script>
export default {
    props: {
        image: {
            type: String,
            default: ''
        }
    }
}
</script>

